import { useTheme } from 'styled-components'

import { selectUserCurrency } from '@redux/features/user/user.selectors'
import { useAppSelector } from '@redux/hooks'
import { useGetCurrenciesQuery } from '@reduxCommonApp/commonApp.api'

import FlagIcon from '@base/FlagIcon'
import useLangCode from '@hooks/useLangCode/useLangCode'
import { UilAngleDown } from '@iconscout/react-unicons'

import { CurrencyWrapper, Wrapper } from './styles'

export const ClassicView = () => {
  const { data: currenciesList } = useGetCurrenciesQuery(true)

  const theme = useTheme()
  const currentLangCode = useLangCode()
  const currentCurrency = useAppSelector(selectUserCurrency)

  const currentCurrencyData = currentCurrency && currenciesList && currenciesList[currentCurrency]

  return (
    <Wrapper>
      <UilAngleDown color={theme.colors.white} size={16} />
      <FlagIcon code={currentLangCode === 'en' ? 'us' : currentLangCode} />
      {!!currentCurrencyData && (
        <CurrencyWrapper>
          <span>{currentCurrencyData?.sign}</span>
          <span>{currentCurrencyData?.label}</span>
        </CurrencyWrapper>
      )}
    </Wrapper>
  )
}
