import styled from 'styled-components'

import { captionSmallStyles } from '@styles/typography/caption'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
`

export const CurrencyWrapper = styled.div`
  ${captionSmallStyles};
  display: inline-flex;
  align-items: center;
  column-gap: 8px;

  span {
    color: ${p => p.theme.colors.white};
  }
`
